.Provider {
    margin-top: 120px;
    min-width: 300px;
    position: fixed;
    right: 20vw;
    top: 0;
    width: 25vw;
    z-index: 100000;
}

@media (max-width: 425px) {
    .Provider {
        right: 2vw;
    }
}
