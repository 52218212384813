.MobileMenuContainer {
    height: 59px;
    width: 100%;
    bottom: 0;
    background: #FFFFFF;
    border-top: 1px solid #D3D9E2;
    position: fixed;
    margin-bottom: 0;
    z-index: 500;
    display: flex;
}

.MobileMenuContainer>ul {
    display: flex;
    justify-content: space-around;
    margin: 0;
    padding: 0;
    width: calc(100% - 35px);
}

.MobileMenuContainerPart2>ul {
    justify-content:start
}

.MobileMenuContainer > a{
    border-top:2px solid #fff; 
}

.MobileMenuContainer>ul>li, .MobileMenuContainerPart2>ul>li {
    width: 50px;
}

.MobileMenuContainer>ul>li>a, .MobileMenuContainerPart2>ul>li>a {
    display: block;
    text-align: center;
    width: 100%;
    height: 100%;
    padding-top: 5px;
}

.MenuItemLabel {
    width: 50px;
    text-align: center;
    font-family: "Lato", sans-serif;
    font-size: 9px;
    line-height: 11px;
    display: block;
    margin-top: -4px;
}

.MoreMenuButton {
    background: none;
    border: none;
    height: 60px;
    width: 35px;
    cursor: pointer;
    padding: 0;
}