@font-face {
    font-family: 'Lato';
    src: local('Lato-Regular'), url('../font/Lato-Regular.woff2');
}

@font-face {
    font-family: 'Lato';
    src: local('Lato-Italic'), url('../font/Lato-Italic.woff2');
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato-Bold'), url('../font/Lato-Bold.woff2');
    font-weight: 700;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato-BoldItalic'), url('../font/Lato-BoldItalic.woff2');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato-Black'), url('../font/Lato-Black.woff2');
    font-weight: 900;
}

@font-face {
    font-family: 'Lato';
    src: local('Lato-BlackItalic'), url('../font/Lato-BlackItalic.woff2');
    font-weight: 900;
    font-style: italic;
}

.primary-color {
    color: #00aaa5;
}

.primary-bgcolor {
    background-color: #00aaa5;
}

.secondary-color {
    color: #ffc412 !important;
}

.secondary-bgcolor {
    background-color: #ffc412;
}

html {
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    font-family: 'Lato', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'Lato', sans-serif;
    color: #1d1d1f;
}

/* Navbar menu customization */
span.as-a.navbar-item {
    cursor: pointer;
}

.navbar-dropdown span.as-a.navbar-item:focus,
.navbar-dropdown span.as-a.navbar-item:hover {
    background-color: whitesmoke;
    color: #0a0a0a;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

p {
    padding: 0;
    margin: 0;
}

img {
    height: auto;
    max-width: 100%;
}

.markerClusterSmall,
.markerClusterMedium,
.markerClusterLarge {
    background-color: rgba(255, 119, 106, 0.391);
    border-radius: 50%;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.markerClusterSmall div {
    background-color: #9a261b;
    border: 3px solid #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.markerClusterSmall div span,
.markerClusterMedium div span,
.markerClusterLarge div span {
    color: #fff;
}

.markerClusterMedium div {
    background-color: #9a261b;
    border: 3px solid #fff;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.markerClusterLarge div {
    background-color: #9a261b;
    border: 3px solid #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*Bretagne Cluster*/

.BretagneMarkerClusterSmall,
.BretagneMarkerClusterMedium,
.BretagneMarkerClusterLarge {
    background-color: rgba(10, 45, 56, 0.15);
    border-radius: 50%;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.BretagneMarkerClusterSmall div {
    background-color: #223163;
    border: 3px solid #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BretagneMarkerClusterSmall div span,
.BretagneMarkerClusterMedium div span,
.BretagneMarkerClusterLarge div span {
    color: #fff;
}

.BretagneMarkerClusterMedium div {
    background-color: #223163;
    border: 3px solid #fff;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BretagneMarkerClusterLarge div {
    background-color: #223163;
    border: 3px solid #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.leaflet-popup {
    margin-bottom: 44px !important;
    max-width: 270px;
    min-width: 244px;
}

.leaflet-popup-content {
    margin: 13px 15px !important;
    min-width: 235px;
}

.leaflet-popup-content-wrapper {
    text-align: center;
}

/* screen reader only */
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.button-icon {
    background: none;
    border: none;
    width: auto;
    height: auto;
    cursor: pointer;
}

.rc-slider-track {
    background-color: #00aaa5 !important;
}

.rc-slider-handle {
    border: solid 2px #00aaa5 !important;
    background-color: #00aaa5 !important;
}

.rc-slider-handle-dragging {
    box-shadow: 0 0 0 5px #71d0cd !important;
}

.rc-slider-dot-active {
    border-color: #00aaa5 !important;
}

/* Accessibility */

.visually-hidden {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
}

.visually-hidden-focusable:focus,
.visually-hidden-focusable:active {
    clip: auto !important;
    -webkit-clip-path: none !important;
    clip-path: none !important;
    height: auto !important;
    margin: auto !important;
    overflow: visible !important;
    width: auto !important;
    white-space: normal !important;
}

.button {
    color: #1d1d1f;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    border: 0;
    font-family: 'Lato', sans-serif;
    border-radius: 10px;
    line-height: 19px;
}

.coloured-button {
    box-shadow: 3px 4px 15px -6px rgba(50, 50, 30, 0.51);
}

.button-yellow {
    background: linear-gradient(90deg, #ffc412 0%, rgba(255, 196, 18, 0.5) 100%);
}

.button-yellow:hover {
    background: linear-gradient(91.99deg, #ffae12 33.43%, #ffffff 160.24%);
}

.button-yellow:focus,
.button-yellow:active {
    background: #ffc412;
}

.button-green {
    background: linear-gradient(90deg, #40bfbb -113.12%, #bde9e7 100%);
}

.button-green:hover {
    background: linear-gradient(90deg, #00b0ab 71.78%, #bde9e7 100%);
}

.button-green:focus,
.button-green:active {
    background: #00aaa5;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
}

.button-white {
    background: #fff;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
    transition: font-size 0.2s ease-in-out;
}

.button-white:hover {
    font-size: 18px;
}

.button-white:active,
.button-white:focus {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
}

.custom-select {
    position: relative;
}

.custom-select select {
    appearance: none;
    background: #ffffff;
    font-family: 'Lato', sans-serif;
    color: #1d1d1f;
    height: 38px;
}

.custom-select select:focus {
    outline: none;
    border: 1px solid #00aaa5;
}

.custom-select:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #1d1d1f;
    pointer-events: none;
}

.label-input {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    display: block;
    color: #1d1d1f;
}

.input-field {
    background: #ffffff;
    border: 1px solid #b0b0b1;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 8px 15px;
    width: 100%;
    transition: border 0.2s ease-in-out;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    color: #1d1d1f;
}

.input-field:focus {
    outline: none;
    border: 1px solid #00aaa5;
}

.special-italic-text {
    font-size: 15px;
    line-height: 18px;
    color: #616161;
    font-style: italic;
    margin: 6px 0 0 0;
    display: block;
}

header.with-banner-notification .menu-burger-mobile {
    max-height: 435px;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: #aaa transparent;
    scrollbar-width: thin;
}

/*Error*/
.red-error {
    color: #ea1b00 !important;
    font-weight: bold;
}

.red-error-field {
    border: 1px solid red !important;
}

.error-container-titre {
    margin: 20px 0 10px 0;
    font-size: 16px !important;
}

.error-container {
    margin: 5px 0 0 0;
    padding: 0;
}

.error-container > li > a {
    display: inline-block;
}

@media (max-width: 425px) {
    .button-white:hover {
        font-size: inherit;
    }
}
