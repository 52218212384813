.ListElementsJuridiques{
    display: flex;
    justify-content: center;
    line-height: 1.3;
}

.ElementsJuridiquesLien:hover {
    color: #00AAA5;
}

.ElementsJuridiquesLien {
    color: #616161;
    padding: 0 16px;
    font-size:15px;
}

.LiensCliquables:not(:last-child):after {
    content: "|";
    color: #616161;
}

@media only screen and (max-width: 1023px) {
    .ListElementsJuridiques{
        display: block;
        margin-bottom: 0;
    }
    
    .LiensCliquables{
        padding-bottom: 16px;
    }

    .LiensCliquables:after {
        display: none;
    }

   .ElementsJuridiquesLien {
        color: #FBFBFD;
        padding: 0;  
    } 
}
