.paginationIsCentered {
    display: flex;
    justify-content: center;
    font-family: "Lato", sans-serif;
    padding-top: 20px;
}

.paginationList {
    display: flex;
    padding-left: 0;
}

.paginationPrevious,
.paginationNext,
.paginationLink {
    border: 0;
    font-family: "Lato", sans-serif;
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
}

/*Pour couleur enum*/

.ColouredButtons_yellow {
    background: #fff3d0;
}

.ColouredButtons_green {
    background: #99dddb;
}

.ColouredButtons_pink {
    background: #ffd8cc;
}

.ColouredButtons_yellow:hover,
.ColouredButtons_yellow_active {
    background: #ffc412;
    cursor: pointer;
}

.ColouredButtons_green:hover,
.ColouredButtons_green_active {
    background: #00aaa5;
    cursor: pointer;
}

.ColouredButtons_pink:hover,
.ColouredButtons_pink_active {
    background: #ff6f61;
    cursor: pointer;
}

.paginationPrevious:disabled,
.paginationNext:disabled {
    display: none;
}

@media (max-width: 425px) {
    .paginationIsCentered {
        flex-direction: column;
        align-items: center;
    }
}
