.Navigation {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  /* Mozilla firefox */
  scrollbar-color: #aaa transparent;
  scrollbar-width: thin;
}

/* Global */
.Navigation::-webkit-scrollbar {
  width: 8px;
}

/* Ascenseur */
.Navigation::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 8px;
}

.Navigation>ul {
  margin: 0;
  padding: 0;
}

.MenuItem {
  align-items: center;
  color: #1d1d1f;
  display: flex;
  line-height: 24px;
  padding: 18px 0 18px 29px;
  min-width: 270px;
}

.MenuItemLabel {
  font-family: "Lato", sans-serif;
  font-size: 20px;
  margin-left: 20px;
}

.MenuItem:hover {
  background: rgba(0, 170, 165, 0.05);
  border-radius: 4px;
}

.MenuItemSelected {
  background: rgba(0, 170, 165, 0.07);
  border-radius: 4px;
  color: #2c827f;
  font-weight: bolder;
  font-size: 40px;
  line-height: 24px;
}