.BlueBackground {
    background: #00AAA5;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.WhiteContainer {
    background: #FFFFFF;
    width: 70%;
    border-radius: 15px;
    padding: 40px 50px 90px 50px;
}

.FormContainer {
    padding-top: 100px;
    max-width: 400px;
    margin: auto;
}

.Logo>img {
    width: 350px;
}

.TitreContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 400px;
    height: 400px;
    margin: auto;
    flex-wrap: nowrap;
    flex-direction: column;
}

.LabelSubmitMessage {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #2c827f;
}

.LabelTitre {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #2c827f;
    padding-bottom: 25px;
    margin: 0;
}

.LabelPassword {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #1D1D1F;
    padding-bottom: 8px;
    display: block;
}

.ContainerPassword {
    position: relative;
    margin-bottom: 20px;
}

.InputPassword {
    background: #FFFFFF;
    border: 1px solid #B0B0B1;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    padding: 10px 15px;
}

.InputPassword:focus {
    outline: none;
    border: 1px solid #00AAA5;
}

.ButtonEye {
    position: absolute;
    right: 5px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 100%;
    padding: 0;
}

.ButtonEye>img {
    opacity: 0.5;
}

.ButtonEye:hover>img {
    opacity: 0.8;
}

.Error {
    position: relative;
    top: 25%;
    display: flex;
    align-self: center;
    padding: 10px;
    color: #ea1b00 !important;
}

.ErrorField {
    border: 1px solid #ea1b00;
}

.ButtonContainer {
    text-align: center;
}

.ButtonContainer>a {
    display: block;
}

.ButtonSubmit {
    margin-top: 25px;
    padding: 10px 20px;
    background: linear-gradient(90deg, #40BFBB -113.12%, #BDE9E7 100%);
    box-shadow: 3px 4px 15px -6px rgba(50, 50, 30, 0.51);
    border-radius: 10px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #1D1D1F;
    border: none;
    cursor: pointer;
}

.ButtonSubmit:hover {
    background: linear-gradient(90deg, #00B0AB 71.78%, #BDE9E7 100%);
}

.ButtonSubmit:focus {
    background: #00AAA5;
}

.TextRequis {
    font-size: 15px;
    line-height: 18px;
    color: #616161;
    font-style: italic;
    margin-bottom: 10px;
}

.SpecialCharactersList {
    font-size: 15px;
    line-height: 18px;
    color: #616161;
    font-style: italic;
    padding-bottom: 10px;
}

::placeholder {
    color: #595959;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
}

@media (max-width:767px) {
    .BlueBackground {
        background: unset;
        display: block;
    }

    .WhiteContainer {
        width: 100%;
        padding: 40px 20px;
    }
}

@media (max-width:425px) {
    .Logo>img {
        width: 280px;
    }
}