.ReseauxSociauxList {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 15px;
}

.ReseauxSociauxListItem {
    padding-right: 25px;
}

.ReseauxSociauxList a {
    display: flex;
    align-items: center;
}

@media (max-width:1023px) {
    .ReseauxSociauxList {
        justify-content: start;
    }
}