.FooterDesktop {
    width: 100%;
    position: relative;
    height: 83px;
} 

.FooterDesktop > svg:nth-child(1) {
    left: 0;
    position: absolute;
    bottom: 0;
}

.FooterDesktop > svg:nth-child(2) {
    right: 0;
    position: absolute;
    bottom: 0;
}