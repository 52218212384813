.CompletionProfilModal {
    border: none;
    border-radius: 15px;
    padding: 40px 55px;
    color: #1d1d1f;
}

.CompletionProfilModal::backdrop {
    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(2px);
}

.CompletionProfilModal > * {
    max-width: 550px;
}

.CompletionProfilModal > p {
    font-size: 16px;
    color: #1d1d1f;
    text-align: center;
}

@media (max-width: 425px) {
    .CompletionProfilModal {
        padding: 20px;
    }
}
