.Card {
  width: 209px;
  height: 326px;
  background: #ffffff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  font-family: "Lato", sans-serif;
  color: #1d1d1f;
  padding: 15px 5px;
  position: relative;
  text-align: center;
}

.Portrait {
  width: 95px;
  height: 95px;
  background: #fff;
  border-radius: 50%;
  object-fit: cover;
  /*Règle le problème de pixelisation sous chrome et opéra*/
  image-rendering: -webkit-optimize-contrast;
}

.isCoordinatrice {
  border: 2px solid #ffc412;
}

.IconeCoordinatrice {
  position: absolute;
  z-index: 2;
  width: 39px;
  height: 39px;
  left: 123px;
  top: 10px;
}

.Nom {
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.ContainerNom {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3.6rem;
}

.ContainerCompagnie {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 38px;
}

.Compagnie {
  font-size: 16px;
  line-height: 19px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Domaine {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0.7rem;
  font-style: italic;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Adresse {
  font-size: 15px;
  line-height: 17px;
  margin-bottom: 1rem;
  color: #616161;
  display: flex;
  justify-content: center;
}

.Ville {
  max-width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 2px;
}

.VoirProfil {
  width: 129px;
  height: 39px;
  position: absolute;
  bottom: 15px;
  right: 0;
  left: 0;
  margin: auto;
  display: block;
  line-height: 2.5rem;
}

@media (max-width: 425px) {
  .Card {
    width: 173px;
    height: 237px;
    padding: 10px 5px;
  }

  .VoirProfil {
    display: none;
  }

  .Portrait {
    width: 70px;
    height: 70px;
  }

  .Nom {
    font-size: 16px;
  }

  .Compagnie {
    font-size: 15px;
  }

  .IconeCoordinatrice {
    width: 30px;
    height: 30px;
    left: 98px;
    top: 5px;
  }
}
