.BannerRating {
    width: 100%;
    position: fixed;
    bottom: 0;
    border: 2px solid rgba(0, 170, 165, 0.5);
    padding: 25px 35px;
    background: #fff;
    z-index: 500;
    font-family: 'Lato', sans-serif;
    color: #1d1d1f;
    font-size: 16px;
    text-align: center;
}

.RadioButtonContainer {
    border: none;
    font-size: 18px;
    padding: 0;
}

.NotationContainer {
    position: relative;
    display: inline-block;
    padding-bottom: 30px;
}

.InputNotationContainer {
    display: flex;
}

.SatisfactionLevel {
    position: absolute;
    bottom: 0px;
    left: -120px;
    font-size: 16px;
    color: #00aaa5;
    font-style: italic;
}

.SatisfactionLevel:last-child {
    position: absolute;
    bottom: 0px;
    right: -60px;
    font-size: 16px;
    left: auto;
}

.RadioButtonContainer > legend {
    width: 100%;
    font-size: 30px;
}

.RadionButtonRating,
.RadionButtonRatingDisabled {
    border: 1px solid #00aaa5;
    border-radius: 50%;
    padding: 10px;
    font-size: 16px;
    margin: 15px 10px 0 0;
    width: 44px;
    height: 44px;
    display: inline-block;
    cursor: pointer;
}

.RadionButtonRating:has(+ span) {
    margin: 15px 0 0 0;
}

.RadionButtonRating:hover {
    background: rgba(0, 170, 165, 0.07);
}

.RadioButtonContainer input[type='radio']:focus + .RadionButtonRating {
    outline: 2px solid #2c827f;
}

.RadioButtonContainer input[type='radio']:checked + .RadionButtonRating {
    background: rgba(0, 170, 165, 0.25);
}

.RadionButtonRatingDisabled,
.RadionButtonRatingDisabled:hover {
    cursor: auto;
    background: #d3d9e2;
    color: #b0b0b1;
    border: none;
}

.InputContainer {
    border: 2px solid rgba(0, 170, 165, 0.5);
    border-radius: 10px;
    display: flex;
    margin: 15px auto 0 auto;
    padding: 10px 15px;
    max-width: 570px;
}

.CommentRatingLabel {
    display: block;
    font-size: 22px;
}

.CommentRatingInput {
    border: none;
    width: calc(100% - 50px);
    background: #ffffff;
    box-shadow: none;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    color: #1d1d1f;
}

.SubmitButton,
.ButtonDesactive {
    box-shadow: 3px 4px 15px -6px rgba(50, 50, 30, 0.51);
    cursor: pointer;
    background: linear-gradient(90deg, #00aaa5 0%, #71d0cd 100%);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
    border: none;
    margin-left: 15px;
}

.SubmitButton:hover {
    background: linear-gradient(90deg, #00b0ab 71.78%, #bde9e7 100%);
    box-shadow: 3px 4px 15px -6px rgba(50, 50, 30, 0.51);
}

.SubmitButton:focus {
    background: #00aaa5;
}

.ButtonDesactive {
    background: #d3d9e2;
}

.BannerRatingCloseButton {
    position: absolute;
    top: 5px;
    right: 15px;
    border: none;
    background-color: transparent;
    font-family: 'Lato', sans-serif;
    margin: 0;
    display: flex;
    align-items: center;
    padding: 0;
    cursor: pointer;
    font-size: 15px;
}

.BannerRatingCloseButton:hover {
    text-decoration: underline;
}

.BannerRatingCloseButton > img {
    margin-left: 5px;
}

@media (max-width: 1023px) {
    .BannerRating {
        bottom: 60px;
    }
}

@media (max-width: 425px) {
    .BannerRating {
        padding: 25px 20px;
    }

    .RadioButtonContainer > legend,
    .CommentRatingLabel {
        font-size: 16px;
    }

    .BannerRatingCloseButton {
        font-size: 14px;
    }

    .SatisfactionLevel {
        bottom: -12px;
        left: -20px;
        max-width: 85px;
    }

    .SatisfactionLevel:last-child {
        bottom: -12px;
        right: -20px;
    }
}
