.Content {
    display: flex;
    position: relative;
    min-height: 100vh;
    color: #1d1d1f;
}

.TitleSecondary {
    font-size: 24px;
    line-height: 29px;
    margin: 0 0 25px 0;
}

.LeftSideLogin {
    width: 50%;
    padding: 15px 40px 110px 40px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
}

.LeftSideLogin > * + *,
.LeftSideLogin > div + p {
    margin: 25px 0 0 0;
}

.RightSideLogin {
    width: 40%;
    padding: 40px 0 40px 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.RightSideLogin > p {
    font-size: 16px;
}

.LeftTextContainer {
    text-align: center;
}

.Title {
    font-size: 24px;
    line-height: 40px;
}

.Text {
    font-size: 20px;
    line-height: 26px;
    font-weight: normal;
    margin: 0;
}

.BoldText {
    font-weight: bold;
}

.BlueText {
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    color: #2c827f;
}

.BlueTitle {
    color: #2c827f;
    padding-left: 8px;
}

.MinimaSociaux {
    font-size: 14px;
    position: relative;
    font-weight: normal;
}

.AvantagesListContainer {
    margin: 15px 0 0 0;
}

.AvantagesList {
    margin: 0;
    display: flex;
    justify-content: space-evenly;
    padding: 0;
    font-size: 14px;
    flex-wrap: wrap;
}

.Avantage {
    flex: 1 1 100px;
    max-width: 170px;
}

.Avantage + .Avantage {
    margin-left: 15px;
}

.ContainerValidationText {
    text-align: center;
    margin: 0 auto;
    font-size: 24px;
    font-weight: bold;
    line-height: 40px;
}

.ContainerValidationText > p {
    margin: 20px 0;
}

.ContainerValidationText > p:first-child {
    font-size: 30px;
}

.ContainerValidationText > p:last-child {
    font-size: 20px;
    color: #616161;
    font-weight: normal;
}

.ValidationText {
    margin: 30px auto 30px auto;
}

.HighlightText {
    background-image: linear-gradient(#ffc41278, #ffc41278);
    padding: 0 2px;
    background-repeat: no-repeat;
    background-position: 0px 17px;
    background-size: 100% 7px;
}

.VagueLogin {
    display: block;
    bottom: 0;
    position: absolute;
    width: 100%;
}

.Label {
    margin: 15px 0 8px 0;
}

.Nom {
    max-width: 350px;
}

.ContainerPassword {
    position: relative;
}

.buttonEye {
    position: absolute;
    right: 5px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 42px;
    padding: 0;
}

.buttonEye > img {
    opacity: 0.5;
}

.buttonEye:hover > img {
    opacity: 0.8;
}

.ButtonContainer {
    text-align: center;
    padding-top: 10px;
}

.ButtonConnexion {
    padding: 10px 20px;
}

.CustomSelect:after {
    top: 17px;
    left: 95%;
}

.FormContainer {
    margin-bottom: 90px;
}

.BeforeInscrivezVous {
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    line-height: 18px;
    color: #1d1d1f;
    margin: 20px 35px 20px 35px;
}

.InscrivezVous {
    text-decoration-line: underline;
    color: #2c827f;
    margin-left: 5px;
    font-weight: bold;
}

.FormInscriptionEtapeContainer input[type='checkbox'] + label::before {
    border: 2px solid #00aaa5;
    height: 16px;
    left: 0;
    top: 3px;
    width: 16px;
}

.FormInscriptionEtapeContainer input[type='checkbox'] + label::after {
    height: 10px;
    left: 3px;
    top: 6px;
    width: 10px;
}

.FormInscriptionEtapeContainer input[type='checkbox'] + label::after,
.FormInscriptionEtapeContainer input[type='checkbox'] + label::before {
    content: '';
    display: inline-block;
    position: absolute;
}

.FormInscriptionEtapeContainer input[type='checkbox']:checked + label::after {
    background: #00aaa5;
}

.FormInscriptionEtapeContainer input[type='checkbox']:focus + label::after {
    outline: 3px solid red;
}

.FormContainer input[type='radio'] + label,
.FormInscriptionEtapeContainer input[type='radio'] + label,
.FormInscriptionEtapeContainer input[type='checkbox'] + label {
    position: relative;
    display: block;
    cursor: pointer;
    font-size: 15px;
    line-height: 18px;
    margin: 20px 0 10px 0;
    padding-left: 24px;
}

.FormInscriptionEtapeContainer input[type='radio'] + label,
.FormInscriptionEtapeContainer input[type='checkbox'] + label {
    margin: 12px 0;
}

.FormContainer input[type='radio'] + label::before,
.FormInscriptionEtapeContainer input[type='radio'] + label::before {
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    border-radius: 50%;
    border: 2px solid #00aaa5;
    width: 18px;
    height: 18px;
}

.FormContainer input[type='radio']:focus + label::before,
.FormInscriptionEtapeContainer input[type='radio']:focus + label::before {
    outline: 2px solid red;
}

.FormContainer input[type='radio'] + label::after,
.FormInscriptionEtapeContainer input[type='radio'] + label::after {
    content: '';
    position: absolute;
    display: inline-block;
    left: 3px;
    top: 3px;
    border-radius: 50%;
    width: 12px;
    height: 12px;
}

.FormContainer input[type='radio']:checked + label::after,
.FormInscriptionEtapeContainer input[type='radio']:checked + label::after {
    background: #00aaa5;
}

.Link {
    color: #1d1d1f;
    text-decoration: underline;
}

::placeholder {
    color: #595959;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
}

.RequiredText {
    margin: 20px 0;
}

.PasswordInstruction {
    margin-bottom: 5px;
}

.Computer:after {
    content: '';
    background: url('./../../../../_common/component/icon/computer.svg');
    width: 79px;
    height: 85px;
    display: block;
    margin: 3px auto 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
}

.Avantage:before {
    content: '';
    width: 130px;
    height: 100px;
    display: block;
    margin: 15px auto 0 auto;
}

.WomenGroup:before {
    background: center no-repeat url('_common/component/icon/womenGroup.svg');
    background-size: contain;
}

.BusinessWoman:before {
    background: center no-repeat url('_common/component/icon/businessWoman.svg');
    background-size: contain;
}

.France:before {
    background: center no-repeat url('_common/component/icon/france.svg');
    background-size: contain;
}

.WomanOnPhone:before {
    background: center no-repeat url('_common/component/icon/womanOnPhone.svg');
    background-size: contain;
}

.InfoBulle {
    display: none;
    color: #1d1d1f;
    background-color: #fff;
    padding: 3px;
    position: absolute;
    top: -60px;
    left: 10px;
    width: 375px;
    z-index: 10;
}

.InformationImage {
    margin-left: 5px;
}

.InformationImage:hover ~ .InfoBulle {
    display: block;
}

.LogoFdt {
    width: 290px;
    height: 80px;
}

.DateNaissance {
    width: 160px;
}

.ValidationPrenom {
    text-transform: capitalize;
}

/* Etapes d'inscription */
.FormInscriptionEtapeTitleSecondary {
    text-align: center;
    margin: 20px 0 0 0;
}

.FormInscriptionEtapeTitleSecondary > .SubTitleSecondary {
    font-weight: normal;
}

.FormInscriptionEtapeTitleSecondary > span:first-child {
    display: block;
}

.FormInscriptionEtapeButtonContainer {
    text-align: right;
    margin: 30px 0 40px 0;
}

.Etape {
    position: absolute;
    font-size: 18px;
    top: 20px;
    right: 45px;
}

.FormInscriptionEtapeContainer fieldset {
    border: none;
    padding: 0;
    margin: 35px 0 40px 0;
}

.LabelEtape {
    font-size: 18px;
    line-height: 24px;
    margin: 15px 0;
}

.FormInscriptionEtapeContainer fieldset legend {
    margin: 0 0 10px 0;
}

@media (max-width: 1023px) {
    .VagueLogin {
        display: none;
    }

    .LogoFdt {
        margin: auto;
        height: 50px;
        width: auto;
    }

    .BoldText {
        font-size: 20px;
        line-height: 24px;
    }

    .BlueText {
        font-size: 20px;
        line-height: 24px;
    }

    .Text {
        font-size: 15px;
        line-height: 18px;
    }

    .TitleSecondary {
        font-size: 20px;
        text-align: center;
        margin: 25px 0 10px 0;
        line-height: 24px;
    }

    .Centrer {
        text-align: center;
    }

    .BeforeInscrivezVous {
        text-align: center;
        margin-bottom: 10px;
    }

    .MotDePasseOublie {
        font-size: 12px;
        line-height: 14px;
    }

    .ButtonConnexion {
        margin-top: 24px;
    }

    .ContainerValidationText {
        margin: 55px auto 20px auto;
        font-size: 18px;
        line-height: 30px;
    }

    .ContainerValidationText > p:first-child {
        font-size: 20px;
    }

    .ContainerValidationText > p:last-child {
        font-size: 16px;
    }

    .Content {
        display: block;
        padding: 20px;
    }

    .LeftSideLogin,
    .RightSideLogin {
        width: 100%;
        padding: 0;
    }

    .FormContainer {
        margin-bottom: 0;
    }

    .InformationImage {
        display: none;
    }

    .LeftSideLogin > div + p {
        margin: 25px 0 0 0;
    }

    .HighlightText {
        background-position: 0px 11px;
    }
}

@media (max-width: 425px) {
    .Computer:after {
        width: 70px;
        height: 70px;
    }

    .AvantagesList {
        display: block;
    }

    .Avantage {
        position: relative;
        margin-top: 30px;
        max-width: unset;
    }

    .Avantage + .Avantage {
        margin-top: 40px;
    }

    .WomenGroup,
    .France {
        margin-left: 85px !important;
    }

    .BusinessWoman,
    .WomanOnPhone {
        margin-right: 85px;
    }

    .Avantage:before {
        width: 90px;
        height: 70px;
        margin: 0;
        position: absolute;
        left: -85px;
        top: -18px;
    }

    .BusinessWoman:after,
    .WomanOnPhone:after {
        content: '';
        width: 90px;
        height: 70px;
        margin: 0;
        position: absolute;
        right: -85px;
        top: -15px;
    }

    .BusinessWoman:before,
    .WomanOnPhone:before {
        display: none;
    }

    .WomanOnPhone:after {
        background: center no-repeat url('_common/component/icon/womanOnPhone.svg');
        background-size: contain;
    }

    .BusinessWoman:after {
        background: center no-repeat url('_common/component/icon/businessWoman.svg');
        background-size: contain;
    }

    .LeftSideLogin > div + p {
        margin: 45px 0 0 0;
    }

    .FormInscriptionEtapeButtonContainer .ButtonConnexion {
        margin-top: 0;
    }

    .Etape {
        font-size: 16px;
        top: 15px;
        right: 20px;
    }
}
