.PageContainer {
    position: relative;
    height: calc(100vh - 177px);
}

.Content {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 100%;
}

.LeftSideSection {
    width: 50%;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    font-family: 'Lato', sans-serif;
}

.RightSideSection {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.LeftSideContent {
    text-align: center;
    width: 68%;
    font-size: 24px;
    color: #1D1D1F;
}

.LeftSideContent h1 {
    font-size: 30px;
    font-weight: normal;
}

.LeftSideContent > p {
    margin: 30px 0;
}

/* .LeftSideSection h1 {
    font-size: 24px;
    margin: 30px 0;
} */

.ReductionForm {
    background: #99dddb;
    padding: 30px;
    border-radius: 10px;
    margin: 0 0 30px 0;
}

.CheckReductionContainer {
    margin-top: 30px;
    display: flex;
    text-align: left;
}

.CheckReductionContainer input[type="checkbox"]+label {
    font-size: 14px;
    color: #1D1D1F;
    padding-left: 30px;
    cursor: pointer;
    position: relative;
}

.CheckReductionContainer input[type="checkbox"]+label::before,
.CheckReductionContainer input[type="checkbox"]+label::after {
    content: '';
    display: inline-block;
    position: absolute;
}

.CheckReductionContainer input[type="checkbox"]+label::before {
    top: 3px;
    left: 0;
    border: 1px solid #616161;
    width: 16px;
    height: 16px;
    background: #fff;
}

.CheckReductionContainer input[type="checkbox"]+label::after {
    top: 6px;
    left: 3px;
    width: 10px;
    height: 10px;
}

.CheckReductionContainer input[type="checkbox"]:checked+label::after {
    background: #00AAA5;
}

.ButtonRenouvellement {
    padding: 10px 15px;
    box-shadow: 3px 4px 15px -6px rgba(50, 50, 30, 0.51);
    margin: 30px 0 0 0;
}

.Error {
    margin-top: 20px;
    color: #ea1b00;
    font-weight: bold;
}

.VagueFdt {
    display: block;
    bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 10;
}

.ValidationPaiement {
    color: #2c827f;
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 80px;
}

.RedValidationPaiement {
    color: #FF6F61;
}

.BoutonPaiement {
    font-size: 16px;
    cursor: pointer;
    padding: 10px 20px;
    font-family: "Lato", sans-serif;
    background: linear-gradient(90deg, #40BFBB -113.12%, #BDE9E7 100%);
    box-shadow: 3px 4px 15px -6px rgba(50, 50, 30, 0.51);
    border-radius: 10px;
    color: #1D1D1F;
    font-weight: bold;
}

.WarningHelloAsso {
    font-style: italic;
    color: #616161;
    line-height: 1.3;
    font-size: 15px;
}

@media (max-width: 1260px) {
    .PageContainer {
        height: 100%;
    }

    .Content {
        display: block;
        margin: 20px 0;
    }

    .RightSideSection, .LeftSideSection {
        width: 100%;
    }

    .RightSideSection {
        margin: 50px auto;
    }

    .ValidationPaiement {
        margin: 50px 0;
    }

    .LeftSideContent h1 {
        font-size: 22px;
    }

    .LeftSideContent {
        font-size: 24px;
    }
}

@media (max-width: 425px) {
    .LeftSideContent {
        width: 90%;
    }

    .PageContainer {
        min-height: 100%;
        height: auto;
    }

    .RightSideSection > img {
        max-width: 90%;
    }
}