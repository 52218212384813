.BlueBackground {
    background: #00AAA5;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.WhiteContainer {
    background: #FFFFFF;
    width: 70%;
    padding: 40px 50px 100px 50px;
    border-radius: 15px;
}

.FormContainer {
    padding-top: 100px;
    max-width: 550px;
    margin: auto;
}

.FormContainer>form {
    width: calc(100% - 150px);
    margin: auto;
}

.Logo>img {
    width: 350px;
}

.LabelTitre {
    position: relative;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #2c827f;
    padding-bottom: 44px;
    margin: 0;
}

.LabelSubmitMessage {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #2c827f;
    margin-top: 130px;
    margin-bottom: 130px;
    text-align: center;
}

.LabelEmail {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #1D1D1F;
    padding-bottom: 8px;
    display: block;
}

.InputMail {
    background: #FFFFFF;
    border: 1px solid #B0B0B1;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 48px;
    width: 100%;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    padding: 10px 15px;
}

.InputMail:focus {
    outline: none;
    border: 1px solid #00AAA5;
}

.Error {
    position: relative;
    top: 25%;
    display: flex;
    align-self: center;
    padding: 10px;
    color: #ea1b00 !important;
}

.ButtonSubmit {
    padding: 10px 20px;
    background: linear-gradient(90deg, #40BFBB -113.12%, #BDE9E7 100%);
    box-shadow: 3px 4px 15px -6px rgba(50, 50, 30, 0.51);
    border-radius: 10px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #1D1D1F;
    border: none;
    cursor: pointer;
}

.ButtonSubmit:hover {
    background: linear-gradient(90deg, #00B0AB 71.78%, #BDE9E7 100%);
}

.ButtonSubmit:focus {
    background: #00AAA5;
}

.ButtonContainer {
    text-align: center;
}

::placeholder {
    color: #595959;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
}

@media (max-width:767px) {
    .LabelEmail {
        right: unset;
    }

    .BlueBackground {
        background: unset;
        display: block;
    }

    .WhiteContainer {
        width: 100%;
        padding: 40px 20px;
    }
}

@media (max-width:425px) {
    .Logo>img {
        width: 280px;
    }

    .FormContainer>form {
        width: 100%;
    }
}