.AppMobileMenuBurgerEtFooter {
  top: 0;
  right: 0;
  width: 301px;
  z-index: 10;
  position: absolute;
  background: #f7fdfa;
  padding-top: 40px;
}

.UserMenuContainer {
  margin-left: 20px;
  display: flex;
  align-items: center;
}

.PortraitUser {
  width: 62px;
  height: 62px;
  margin-right: 8px;
  object-fit: cover;
  background: #ececec;
  border-radius: 50%;
  max-height: unset !important;
  /* position: absolute; */
}

.UserNameLabel {
  font-size: 18px;
  line-height: 22px;
  color: #1d1d1f;
  font-weight: bold;
  text-transform: capitalize;
}

.AppMobileMenuBurger {
  padding-top: 28px;
}

.AppMobileMenuBurger>ul {
  margin: 0;
  padding: 0;
}

.MenuBurgerItem {
  font-family: "Lato", sans-serif;
  color: #1d1d1f;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  padding: 0 0 0 24px;
  background-color: #f7fdfa;
  border: 0;
  cursor: pointer;
  width: 100%;
}

.MenuBurgerItemInactive {
  opacity: 0.6;
  cursor: auto;
}

.MenuBurgerItemIcon {
  padding-left: 9px;
}

.MenuBurgerItemLabel {
  padding-left: 25px;
  width: 100%;
  text-align: left;
}

.FooterInHeader {
  font-family: "Lato", sans-serif;
  font-size: 15px;
  line-height: 18px;
  padding: 30px 0 22px 25px;
  background: #00AAA5;
  height: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
}