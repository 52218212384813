.PageContainer {
    background: #ffffff;
    font-family: 'Lato', sans-serif;
    color: #1d1d1f;
    max-width: 1420px;
    margin: 0 auto;
}

section.PageContainer h1 {
    font-size: 24px;
    line-height: 29px;
    margin: 0 0 20px 0;
}

.PageContainer .Description {
    font-size: 16px;
    margin-bottom: 35px;
}

section.PageContainer > img {
    margin-bottom: -5px;
}

.SliderTitle {
    margin-bottom: 14px;
    font-size: 16px;
    font-weight: bold;
}

.FiltreTitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #1d1d1f;
    padding-left: 8px;
    margin: 0;
    display: inline-block;
}

.FiltreResult {
    font-style: italic;
}

.FiltresContainer1stRow,
.FiltresContainer2ndRow {
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.SelectContainer {
    width: 315px;
    padding: 20px 45px 9px 0;
    margin-right: 10px;
}

.LabelInput {
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    display: block;
    color: #1d1d1f;
    padding-bottom: 8px;
}

.CustomSelect select option[value=''] {
    color: #b0b0b1;
    font-style: italic;
}

.CustomSelect select {
    border: 1px solid #d3d9e2;
    border-radius: 10px;
    padding: 10px 25px 10px 10px;
    width: 100%;
    font-size: 14px;
}

.CustomSelect:after {
    left: 90%;
    top: 4px;
    margin-top: 15px;
}

.SecteurActiviteSelectContainer {
    width: 475px;
}

.SecteurActiviteSelectContainer .CustomSelect:after {
    left: 94%;
}

.SearchInput {
    padding: 10px 20px 10px 35px;
    position: relative;
    width: 270px;
    height: 39px;
    background: #ffffff;
    border: 1px solid #d3d9e2;
    border-radius: 10px;
    font-size: 14px;
    font-family: 'Lato', sans-serif;
}

.SearchInput:focus {
    outline: none !important;
    border: 1px solid #00aaa5;
}

.SearchIcon {
    position: absolute;
    top: 57px;
    left: 10px;
}

.Loupe {
    width: 18px;
    height: 18px;
}

.Button {
    border-radius: 10px;
    height: 39px;
    width: 100px;
    margin-top: 41px;
    border: 0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    font-family: 'Lato', sans-serif;
    color: #1d1d1f;
    background-color: #fff;
    cursor: pointer;
    margin-left: 50px;
}

.BackgroundTitleContainer {
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 34px 30px;
}

.CardsContainer {
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, 209px);
    justify-content: center;
    grid-gap: 42px 15px;
    padding: 0;
    margin: 0;
}

@media (max-width: 1023px) {
    .PageContainer {
        margin-top: 30px;
        margin-bottom: 0px;
    }

    .FiltresContainer {
        margin-left: 20px;
    }
}

@media (max-width: 550px) {
    .BackgroundTitleContainer,
    .CardsContainer,
    .PageContainer {
        padding: 0;
        background: transparent;
        box-shadow: unset;
    }

    .CardsContainer {
        padding-top: 10px;
        padding-bottom: 20px;
        gap: 16px 14px;
        margin: 16px 0;
    }
}

@media (max-width: 425px) {
    .PageContainer {
        margin: 10px auto auto auto;
    }

    section.PageContainer h1 {
        font-size: 20px;
        line-height: 24px;
        margin: 0 0 15px 10px;
    }

    .PageContainer .Description {
        margin: 0 10px 10px 10px;
    }

    .FiltresContainer {
        padding: 19px 21px 11px 21px;
        margin: 0 10px 16px 10px;
        background: #ffffff;
        border-radius: 10px;
    }

    .FiltreButton {
        background: #ffffff;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        cursor: pointer;
        padding: 20px 35px;
        width: 109px;
        height: 40px;
        position: relative;
        border: none;
    }

    .FiltreImg {
        position: absolute;
        width: 20px;
        height: 18px;
        bottom: 10px;
        left: 7px;
    }

    .FiltreTitle {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        position: absolute;
        top: 11px;
        left: 29px;
    }

    .SearchTitle {
        margin-bottom: 14px;
        margin-top: 27px;
        font-size: 16px;
        font-weight: bold;
    }

    .SearchControl {
        position: relative;
    }

    .SearchIcon {
        bottom: 6px;
        left: 12px;
        top: inherit;
    }

    .SelectContainer {
        width: 100%;
        padding: 10px 0;
        margin-right: 0;
    }

    .LabelInput {
        font-size: 15px;
        margin-top: 16px;
    }

    .CustomSelect:after,
    .SecteurActiviteSelectContainer .CustomSelect:after {
        left: 93%;
    }

    .Button {
        margin-top: 31px;
        margin-left: 0;
    }

    .CardsContainer {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 16px 14px;
        padding: 10px 0 20px 0;
    }

    .FiltresContainer1stRow,
    .FiltresContainer2ndRow {
        display: block;
    }

    .FiltreResult {
        margin: 20px 10px 15px 10px;
        display: block;
    }
}
