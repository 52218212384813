.Navbar {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1000;
    background: #fff;
}

.NavbarContainer {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
    z-index: 30;
}

.NavLogo {
    width: 100%;
    align-self: flex-start;
    padding-top: 4px;
    padding-left: 8px;
    margin-right: 15px;
    min-width: 185px;
    max-width: 750px;
}

.SearchControl {
    display: flex;
    width: 100%;
    max-width: 500px;
}

.SearchInput {
    display: flex;
    min-width: 70%;
    max-width: 175px;
    height: 44px;
    background: #ffffff;
    border: 2px solid #00aaa5;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 15px;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
}

.SearchInput::placeholder {
    color: #595959;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
}

.SearchInput:focus {
    outline: none !important;
    border: 1px solid #00aaa5;
}

.Messagerie {
    width: 102px;
    margin-right: 20px;
    margin-left: 20px;
    min-width: 40px;
    text-align: center;
    position: relative;
}

.RedDot {
    background: #ff6f61;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    left: 55px;
    bottom: 33px;
}

.RedDotNotifications {
    background: #FF6F61;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    left: 50px;
    bottom: 33px;
}

.SearchIcon {
    position: relative;
    width: 24px;
    height: 24px;
    top: 10px;
    right: 35px;
}

.SearchMobile {
    display: none;
    min-width: 40px;
    height: 35px;
    padding: 6px;
    border-radius: 15px;
    background: #ffffff;
    border: 2px solid #00aaa5;
    box-sizing: border-box;
    position: relative;
    right: 30px;
    top: -4px;
}

.ItemsContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    max-width: 500px;
}

.ItemIcon {
    display: flex;
    margin: auto;
}

.ItemLabel {
    font-size: 18px;
    line-height: 22px;
    color: #1d1d1f;
}

.Accueil {
    width: 102px;
    min-width: 40px;
    text-align: center;
}

.Notification {
    width: 102px;
    min-width: 40px;
    text-align: center;
    position: relative;
}

.MenuUtilisatrice {
    max-width: 300px;
    z-index: 30;
    margin-left: 8%;
    margin-right: 10px;
}

.NameAndPhotoContainer {
    display: flex;
    align-items: center;
    padding: 5px;
    border: 0;
    background: transparent;
    cursor: pointer;
}

.NameContainer {
    display: flex;
    align-items: center;
}

.PortraitUser {
    width: 70px;
    height: 70px;
    object-fit: cover;
    background: #ececec;
    border-radius: 50%;
    z-index: 10;
    margin-right: 8px;
    max-height: unset !important;
    image-rendering: -webkit-optimize-contrast;
}

.UserNameLabel {
    font-size: 18px;
    line-height: 22px;
    color: #1d1d1f;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 7px;
    font-family: "Lato", sans-serif;
    text-transform: capitalize;
}

.UnfoldedUserMenu {
    display: flex;
    flex-direction: column;
    background-color: white;
    position: absolute;
    border-radius: 8px;
    top: 80px;
    right: 0;
    width: 235px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    z-index: 2000;
}

.UnfoldedUserMenu > ul {
    margin: 0;
    padding: 0;
}

.UserMenuItem {
    color: #1d1d1f;
    padding: 10px 10px 10px 30px;
    font-size: 16px;
    transition: font-size 0.2s ease-in-out;
    display: block;
    text-align: left;
    background: #fff;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    font-family: "Lato", sans-serif;
    width: 100%;
}

.UserMenuItem:hover {
    font-size: 18px;
}

.UserMenuItemInactive:hover {
    font-size: 16px;
}

.IconNavLinkInactive,
.UserMenuItemInactive {
    opacity: 0.6;
    cursor: auto;
}

.Vague {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -5;
}

.MobileMenu {
    position: absolute;
    right: 0;
    display: none;
    width: 100%;
    max-width: 320px;
    background-color: white;
    z-index: 35;
}

.MobileMenuIcon {
    display: none;
    z-index: 40;
    min-width: 24px;
}

.MobileMenuCloseButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    border: none;
    background-color: #f7fdfa;
    height: 36px;
    z-index: 35;
}

.MobileMenuCloseButton:hover {
    background-color: #eee;
}

.Bandeau {
    background-color: #2c827f;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Text {
    font-family: "Lato", sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
}

.Link {
    line-height: 18px;
    margin-left: 5px;
    text-decoration: underline;
    color: #FFFFFF;
}

.BannerNotification, .BannerImage, .BannerDescription > div {
    display: flex;
    align-items: center;
}

.BannerNotification {
    background: #FFEEBB;
    padding: 15px 0 25px 0;
    position: relative;
    font-family: 'Lato', sans-serif;
    color: #1d1d1f;
    box-shadow: 0 -5px 18px 0 #0000001A inset;
}

.BannerImage {
    min-width: 280px;
    justify-content: right;
}

.BannerImage > img {
    width: 40px;
}

.BannerImage > span {
    text-transform: uppercase;
    font-size: 23px;
    margin-right: 10px;
}

.BannerNotification > .BannerDescription {
    font-size: 16px;
    overflow: auto;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    margin: 0 6.2%;
}

.BannerDescription > div {
    border-left: 2px solid #FFC926;
    width: 100%;
    margin: 0 auto;
    max-width: 1160px;
    min-height: 48px;
    padding-left: 40px;
}

.BannerDescription a {
    padding: 10px 16px;
    margin-left: 20px;
    text-align: center;
}

.BannerNotificationCloseButton {
    position: absolute;
    bottom: 5px;
    right: 15px;
    border: none;
    background-color: transparent;
    font-family: 'Lato', sans-serif;
    margin: 0;
    display: flex;
    align-items: center;
    padding: 0;
    cursor: pointer;
    font-size: 15px;
}

.BannerNotificationCloseButton:hover {
    text-decoration: underline;
}

.BannerNotificationCloseButton > img {
    margin-left: 5px;
}

@media (max-width: 1380px) {
    .BannerNotification {
        padding: 5px 0 35px 0;
    }
}

@media (max-width: 1200px) {
    .MenuUtilisatrice {
        margin-left: 4%;
    }
}

@media screen and (max-width: 1023px) {
    .MenuUtilisatrice {
        display: none;
    }

    .ItemLabel {
        display: none;
    }

    .Accueil {
        display: none;
    }

    .MobileMenu {
        display: block;
        top: 0;
    }

    .MobileMenuIcon {
        display: block;
    }

    .RedDot {
        left: 22px;
        bottom: 13px;
    }

    .RedDotNotifications {
        left: 18px;
        bottom: 12px;
    }

    .ItemsContainer {
        width: 180px;
        justify-content: space-around;
        margin: 0 10px 30px 10px;
    }

    .Messagerie {
        margin-left: 10px;
        margin-right: unset;
        width: unset;
        margin-top: 8px;
    }

    .Notification {
        width: unset;
        margin-top: 10px;
    }

    .MenuBurger {
        margin-top: 10px;
        border: 0;
        background-color: transparent;
        padding: 0;
    }

    .SearchInput {
        min-width: 30%;
        max-width: 50%;
    }

    .NavLogo {
        min-width: 125px;
        max-height: 70px;
    }

    .Bandeau {
        padding: 7px;
    }

    .Text {
        font-size: 15px;
        line-height: 12px;
        text-align: center;
    }

    .BannerImage {
        min-width: 200px;
    }
}

@media screen and (max-width: 660px) {
    .ItemLabel {
        display: none;
    }

    .SearchInput {
        display: none;
    }

    .SearchIcon {
        display: none;
    }

    .SearchMobile {
        display: block;
        margin-top: 10px;
    }

    .Loupe {
        position: relative;
        left: 1px;
        bottom: 2px;
    }

    .NavLogo {
        align-self: flex-start;
        min-width: 125px;
        margin-right: 45px;
    }

    .RedDotNotifications {
        left: 20px;
        bottom: 12px;
    }

    .Logo {
        max-width: 121px;
    }
}

@media screen and (max-width: 425px) {
    .BannerNotification {
        display: block;
    }

    .BannerImage {
        justify-content: center;
    }

    .BannerImage > span {
        font-size: 16px;
    }

    .BannerImage > img {
        width: 30px;
    }

    .BannerNotification > .BannerDescription {
        margin: auto;
        font-size: 14px;
    }

    .BannerDescription > div {
        display: block;
        height: auto;
        margin: auto;
        max-width: 100%;
        border: 0;
        padding: 0 10px;
        text-align: center;
    }

    .BannerNotification a {
        display: block;
        max-width: 190px;
        margin: 10px auto 8px auto;
        padding: 8px;
    }

    .BannerNotificationCloseButton {
        font-size: 14px;
    }
}

@media screen and (max-width: 360px) {
    .ItemsContainer {
        width: 130px;
    }
}
