.ContainerLogin {
    position: relative;
    height: 100vh;
}

.Content {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 100%;
}

.LeftSideLogin {
    width: 52%;
    position: relative;
}

.LeftSideLogin > img {
    object-fit: cover;
    border-radius: 0 50% 50% 0;
    position: absolute;
    top: -155px;
    right: 22px;
    width: calc(100% + 22px);
    height: calc(100% + 155px);
    z-index: 2;
}

.ImageBackground {
    border-radius: 0 50% 50% 0;
    position: absolute;
    background: linear-gradient(180deg, rgba(255, 196, 18, 0.75) 0%, rgba(255, 196, 18, 0.4) 99.96%);
    z-index: 1;
    top: -150px;
    right: 0;
    width: calc(100% + 10px);
    height: calc(100% + 145px);
}

.VagueLogin {
    display: block;
    bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 10;
}

.RightSideLogin {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 48%;
}

.RightSideLogin form {
    max-width: 525px;
    width: 100%;
}

.Label {
    margin: 30px 0 8px 0;
}

.TitreForm {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
    margin: 0 0 45px 0;
    color: #1d1d1f;
}

.ContainerPassword {
    position: relative;
}

.buttonEye {
    position: absolute;
    right: 5px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 100%;
    padding: 0;
}

.buttonEye > img {
    opacity: 0.5;
}

.buttonEye:hover > img {
    opacity: 0.8;
}

.ButtonContainer {
    text-align: center;
    margin-top: 40px;
}

.ButtonConnexion {
    padding: 10px 20px;
}

.LogoFdt {
    position: absolute;
    width: 290px;
    height: 80px;
    top: 48px;
    left: 50%;
}

.RightSideLogin input:focus {
    outline: none;
    border: 1px solid #00aaa5;
}

.MotDePasseOublie {
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    line-height: 18px;
    text-decoration-line: underline;
    color: #616161;
    margin-top: 10px;
    display: block;
}

.InscrivezVous {
    text-decoration-line: underline;
    color: #2c827f;
    margin-left: 5px;
    font-weight: bold;
}

::placeholder {
    color: #595959;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
}

.WarningErrorContainer > p {
    margin: 10px 0 0 0;
    font-size: 15px;
    line-height: 19px;
    color: #1d1d1f;
}

.WarningErrorContainer > p:first-child {
    color: #ea1b00;
    font-weight: bold;
    font-style: italic;
}

.newLinkForm {
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #616161;
}

.newLinkForm > p:first-child {
    font-size: 30px;
    line-height: 36px;
    font-weight: bold;
    color: #1d1d1f;
}

.newLinkForm > *:not(label) {
    margin: 0 0 50px 0;
}

.newLinkForm > label {
    margin: 0 0 20px 0;
}

.ButtonConnexion:disabled {
    background: #d3d9e2;
    color: #b0b0b1;
    cursor: default;
}

@media (max-width: 1260px) {
    .Content {
        flex-direction: column;
        align-items: center;
    }

    .LogoFdt {
        position: unset;
        width: unset;
        height: 33px;
        margin-top: 140px;
    }

    .TitreForm {
        font-size: 16px;
        line-height: 19px;
        font-weight: bold;
        margin: 20px 0 0 0;
        text-align: center;
    }

    .LeftSideLogin > img {
        width: 150px;
        height: 153px;
        border-radius: 50%;
        top: -18px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .ImageBackground {
        width: 153px;
        height: 153px;
        background: linear-gradient(180deg, rgba(255, 196, 18, 0.75) 0%, rgba(255, 196, 18, 0.4) 99.96%);
        border-radius: 50%;
        top: -18px;
        left: 5px;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .MessageAdherenteLogin {
        padding: 5px 2px;
        width: 288px;
        top: 195px;
        left: calc(50% - 144px);
        border: 4px solid #ff6f61;
    }

    .TitreMessageLogin {
        margin: 0 0 10px 0;
        font-size: 16px;
        line-height: 19px;
    }

    .MessageAdherenteLogin > p {
        font-size: 14px;
        line-height: 18px;
        padding: 0;
    }

    .RightSideLogin {
        width: auto;
        margin: 0 10px;
    }

    .BeforeInscrivezVous {
        text-align: center;
        margin-top: 70px;
    }

    .ButtonConnexion {
        margin-top: 24px;
    }

    .newLinkForm {
        margin: 30px 0 0 0;
    }

    .newLinkForm > p:first-child {
        font-size: 24px;
    }

    .newLinkForm > * {
        margin: 0 0 30px 0;
    }
}

@media (max-width: 425px) {
    .ContainerLogin {
        min-height: 100%;
        height: auto;
    }

    .VagueLogin {
        display: none;
    }

    .RightSideLogin form > label {
        margin: 16px 0 8px 8px;
    }

    .MotDePasseOublie {
        margin-left: 10px;
    }
}
