.AppMenuContentContainer {
    display: flex;
    margin-top: 70px;
    margin-bottom: 70px;
}

.AppLeftMenuContainer {
    height: 100%;
    margin-left: 10px;
}

.AppLeftMenu {
    height: 90vh;
    min-width: 280px;
}

.AppContent {
    overflow: auto;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    margin: 0 6.2%;
    padding: 10px 10px 50px 10px;
}

.ConnexionContainer {
    height: 100%
}

@media (min-width: 1920px) {
    .AppContent {
        margin: 0 5.2%;
    }
}

@media screen and (max-width: 1023px) {
    .AppContent {
        margin-left: 0;
        margin-right: 0;
        padding: 0 0 30px 0;
    }

    .AppMenuContentContainer {
        margin-top: 0;
    }
}

@media (max-width: 425px) {
    .AppContent {
        background-color: #f7fdfa;
        z-index: 0;
        margin-bottom: 60px;
    }

    .AppMenuContentContainer {
        margin-bottom: 0;
    }
}