.Notification {
    box-shadow: 0 0.5em 0.9em -0.125em rgba(10, 10, 10, 0.2),
    0 0 0 1px rgba(10, 10, 10, 0.02);
    border-radius: .375em;
    position: absolute;
    padding: 1.25rem 2.5rem 1.25rem 1.5rem;
}

.is-info {
    background-color: #3e8ed0;
    color: #fff;
}

.is-success {
    background-color: #48c78e;
    color: #fff;
}

.is-warning {
    background-color: #ffe08a;
    color: rgba(0, 0, 0, .7);
}

.is-danger {
    background-color: #f14668;
    color: #fff;
}

.Delete {
    cursor: pointer;
    max-height: 20px;
    max-width: 20px;
    min-height: 20px;
    min-width: 20px;
    right: .5rem;
    position: absolute;
    top: .5rem;
    border: none;
    border-radius: 50%;
    background-color: rgba(10, 10, 10, .1);
}

.Delete:hover {
    background-color: rgba(10, 10, 10, .3);
}

.Delete::after {
    height: 2px;
    width: 50%;
}

.Delete::before {
    height: 2px;
    width: 50%;
}

.Delete::after, .Delete::before {
    background-color: #fff;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
    transform-origin: center center;
}
